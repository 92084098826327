.reviews__title {
  display: none;
}

.reviews {
  .r-tabs .r-tabs-nav {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    padding-right: 15px;
    padding-left: 15px;
    border-bottom: 1px solid #bebebe;
  }

  .r-tabs-tab {
    flex-grow: 1;
    padding-bottom: 13px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    font-family: $second-font;
    text-align: center;
    color: #000000;


    &.r-tabs-state-active {
      border-bottom: 2.5px solid #3781ce;
    }
  }

  .r-tabs-accordion-title {
    display: none;
  }

  .r-tabs-panel {
    padding: 0;
  }
}

.reviews__list {
  @include list-reset;
  margin: 0;
  padding-top: 30px;
}

.reviews__item {
  padding: 30px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
}

.reviews__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: $second-font;

  color: #000000;
}

.reviews__blank {
  span {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    font-family: $second-font;
    color: #0065bb;
    text-transform: uppercase;
  }

  img {
    display: none;
  }
}

.reviews__name {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  font-family: $second-font;
  color: #000000;
}

.reviews__date {
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 20px;
  font-family: $second-font;
  color: #a7a7a7;
}

.reviews__input {
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 11px;
  font-size: 14px;
  line-height: 20px;
  font-family: $second-font;
  color: #5e5e5e;
  border: none;
  border-bottom: 0.5px solid #b5b5b5;
  appearance: none;

  &:focus {
    border-color: $main-blue;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 20px;
    color: #5e5e5e;
  }
}

.reviews__textarea {
  width: 100%;
  margin-bottom: 32px;
  padding-bottom: 11px;
  font-size: 14px;
  line-height: 20px;
  font-family: $second-font;
  color: #5e5e5e;
  border: none;
  border-bottom: 0.5px solid #b5b5b5;
  appearance: none;

  &:focus {
    border-color: $main-blue;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 20px;
    color: #5e5e5e;
  }
}

.reviews__politic {
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-family: $second-font;
  color: #000000;

  a {
    color: $text-blue;
  }
}

.reviews__submit {
  padding-top: 14px;
  padding-bottom: 14px;
}

@media (min-width: 1200px) {
  .reviews__title {
    display: inline-block;
  }

  .reviews__wrapper {
    display: flex;
    align-items: flex-start;

    .reviews__list {
      margin-top: 60px;
      padding-right: 10px;
      border-right: 1px solid rgba(187, 187, 187, 0.4);
    }

    .reviews__item {
      padding: 0;
    }

    .reviews__item-inner {
      &::before {
        display: none;
      }
    }
  }

  .reviews__form {
    width: 235px;
    margin-top: 100px;
    margin-left: 50px;
  }

  .reviews {
    .r-tabs .r-tabs-nav {
      margin: 0;
      padding: 0;
    }

    .r-tabs {
      margin-top: 30px;
    }

    .r-tabs-tab {
      font-size: 14px;
    }
  }

  .reviews__name {
    margin-bottom: 8px;
  }

  .reviews__date {
    margin-bottom: 19px;
  }

  .reviews__text {
    max-width: 515px;
  }

  .reviews__list {
    padding-top: 0;
  }

  .reviews__item {
    padding: 50px 0;
  }

  .reviews__item-inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 85px;
      z-index: -1;
      display: block;
      width: 315px;
      height: 100%;
      background: linear-gradient(89.58deg, #ffffff 0.54%, #e9e9e9 99.7%);
    }
  }

  .reviews__blank {
    display: block;
    width: 226px;
    box-shadow: 0 0 4px rgba(124, 124, 124, 0.25);

    span {
      display: none;
    }

    img {
      display: block;
    }
  }
}
