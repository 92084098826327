.callback {
  padding: 60px 15px;
  font-family: $second-font;
  color: #ffffff;
  background-color: #1d283e;
}

.callback__icon {
  display: none;
}

.callback__title {
  margin-bottom: 25px;
  padding-bottom: 15px;
  font-size: 20px;
  border-bottom: 1px solid #dbdbdb;
}

.callback__text {
  font-size: 16px;
}

.callback__form {
  margin-top: 43px;

  .input {
    margin-bottom: 14px;

    &--file {
      margin-bottom: 34px;
    }
  }

  .textarea {
    margin-bottom: 34px;
  }
}

@media (min-width: 768px) {
  .callback__form {
    display: flex;

    .input {
      margin-bottom: 19px;

      &--file {
        margin-bottom: 0;
      }
    }

    .callback__bottom {
      display: flex;
      margin-top: 10px;
    }

    .textarea {
      min-height: 122px;
      margin-bottom: 0;
    }
  }

  .callback__left {
    width: 50%;
    margin-right: 22px;
  }

  .callback__right {
    width: 50%;
  }

  .callback__icon {
    display: block;
    width: 21px;
    height: 18px;
    transition: all 0.3s;
    fill: #f4f4f4;

    &:hover {
      fill: $main-blue;
    }
  }

  .callback__button {
    width: auto;
    padding: 0;
    background-color: transparent;
    border: unset;

    &:hover {
      background-color: transparent;
    }

    span {
      display: none;
    }
  }
}

@media (min-width: 1200px) {
  .callback {
    @include page-wrapper;
    padding-top: 110px;
    padding-bottom: 100px;
  }

  .callback__title {
    margin-bottom: 95px;
  }

  .callback__inner {
    display: flex;
    justify-content: space-between;
  }

  .callback__text {
    max-width: 520px;
    font-size: 18px;
  }

  .callback__form {
    width: 615px;
    margin-top: 0;
  }
}
