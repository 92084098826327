.jGrowl.top-right {
  right: 20px !important;
  top: 0 !important;
}

.jGrowl {
  font-size: 14px !important;
  letter-spacing: 0.03em !important;
  font-weight: 700 !important;
  font-family: "Proxima Nova" !important;
  line-height: 23px  !important;
}

.jGrowl-notification {
  background-color: #0065BB !important;
  opacity: 1 !important;
  width: 100% !important;
  padding: 18px !important;
  border-radius: 0 !important;
}

.jGrowl-notification .jGrowl-close {
  display: none !important;

}

@media (min-width: 1200px) {
  .jGrowl-notification {
    padding-right: 85px !important;
  }
}
