.promo {
  padding: 40px 15px;
}

.promo__img {
  width: 100%;
  margin-bottom: 34px;
}

.promo__small {
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  font-family: $second-font;
  color: $text-blue;
}

.promo__title {
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  font-family: $second-font;
  color: #545454;
}

.promo__desc {
  margin-bottom: 45px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  font-family: $second-font;

  color: #6b6b6b;
}

.promo__controls {
  display: flex;
  flex-direction: column;

  .button {
    margin-bottom: 20px;
    padding-top: 17.5px;
    padding-bottom: 17.5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.03em;

    &--transparent {
      color: $text-blue;
    }
  }
}

@media (min-width: 1200px) {
  .promo {
    @include page-wrapper;
    padding-top: 0;
    padding-bottom: 0;
    background-image: url("../../img/promo__bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .promo__inner {
    position: relative;
    min-height: 740px;
    padding-top: 135px;
    padding-bottom: 25px;
  }

  .promo__img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 700px;
  }

  .promo__small {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }

  .promo__title {
    max-width: 720px;
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 47px;
    line-height: 55px;
    color: #ffffff;
  }

  .promo__desc {
    max-width: 720px;
    margin-bottom: 38px;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
  }

  .promo__controls {
    flex-direction: row;
    align-items: flex-start;
    max-width: 470px;

    .button {
      width: 50%;
      margin-bottom: 0;

      &--transparent {
        color: #ffffff;
        border-color: #ffffff;
      }

      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }
}
