@font-face {
  font-weight: 400;
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat-Regular.woff2") format("woff2"), url("../../fonts/Montserrat-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat-Medium.woff2") format("woff2"), url("../../fonts/Montserrat-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../../fonts/Montserrat-SemiBold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat-Bold.woff2") format("woff2"), url("../../fonts/Montserrat-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: "Proxima Nova";
  src: url("../../fonts/ProximaNova-Regular.woff2") format("woff2"), url("../../fonts/ProximaNova-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-family: "Proxima Nova";
  src: url("../../fonts/ProximaNova-Semibold.woff2") format("woff2"), url("../../fonts/ProximaNova-Semibold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: "Proxima Nova";
  src: url("../../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../../fonts/ProximaNova-Bold.woff") format("woff");
  font-display: swap;
}
