.header {
  position: relative;
  z-index: 100;
  font-family: $second-font;

  .menu__btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.header__work {
  display: none;
}

.header__catalog {
  display: none;
}

.header__contact {
  display: none;

  &:first-child {
    display: inline-block;
    margin-left: 35px;

    .header__callback {
      display: none;
    }
  }
}

.header__link {
  font-weight: 600;
  font-size: 16px;
  color: $black;
  letter-spacing: 0.03em;
  transition: all 0.3s;

  &:hover {
    color: $text-blue;
  }
}

.header__top {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
}

.header__logo {
  display: block;
  width: 97px;
  margin-bottom: -5px;
  margin-left: 15px;
}

.header__search {
  display: none;
}

.header__button {
  display: none;
}

@media (max-width: 320px) {
  .header__contact {
    &:first-child {
      display: none;
    }
  }
}

@media (min-width: 1200px) {
  .header {
    @include page-wrapper;
    padding-top: 28px;

    .menu__btn {
      display: none;
    }
  }

  .header__contacts {
    display: flex;
  }

  .header__contact {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    padding-left: 25px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 1px;
      height: 20px;
      background: #d8d8d8;
      transform: translateY(-50%);
    }

    &:first-child {
      display: flex;
      margin-left: 25px;

      .header__callback {
        display: inline-block;
      }
    }
  }

  .header__callback {
    margin-top: -5px;
    font-weight: 400;
    font-size: 14px;
    color: $text-blue;

    &:hover {
      color: $black;
    }
  }

  .header__logo {
    width: unset;
    margin-bottom: 0;
    margin-left: 0;

    img {
      width: 103px;
    }

    .logo__text {
      max-width: 275px;
      margin-left: 20px;
    }
  }


  .header__top {
    padding-bottom: 13px;
  }

  .header__work {
    display: inline-block;
    margin-left: auto;
    font-weight: 600;
  }

  .header__catalog {
    position: relative;
    display: block;
    margin-bottom: -20px;
    padding-bottom: 20px;

    & > a {
      display: inline-flex;
      align-items: center;
      padding: 13.5px 21px 13.5px 15px;
      font-weight: 400;
      font-size: 15px;
      color: #ffffff;
      text-transform: uppercase;
      background-color: $main-blue;
    }

    &:hover {
      .header__cats {
        display: flex;
      }
    }
  }

  .header__cats {
    @include list-reset;
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 100;
    display: none;
    flex-direction: column;
    flex-wrap: wrap;
    width: 875px;
    height: 460px;
    padding: 40px 35px;
    background: #ffffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }

  .header__cat {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 15px;
    color: $black;
    transition: all 0.3s;

    &:hover {
      color: $text-blue;
    }
  }

  .header__catalog-icon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    fill: #ffffff;
  }

  .header__bottom {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
  }

  .header__button {
    display: inline-block;
    margin-left: auto;
  }

  .header__search {
    display: block;
  }

  .header__search-row {
    @include page-wrapper;
    position: absolute;
    top: 201px;
    left: 0;
    display: none;
    width: 100%;
    padding-top: 34px;
    padding-bottom: 44px;
    background-color: #ffffff;

    button {
      position: absolute;
      left: -9999px;
    }

    input {
      width: 595px;
      padding-bottom: 17px;
      font-weight: 600;
      font-size: 16px;
      font-family: $second-font;
      color: #696969;
      background: transparent;
      border: none;
      border-bottom: 1px solid $main-blue;
      appearance: none;

      &::placeholder {
        font-weight: 600;
        font-size: 16px;
        color: #696969;
      }
    }
  }

  .header__loupe {
    display: block;
    height: 18px;
  }

  .header__search-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    transition: all 0.3s;
    fill: $black;

    &:hover {
      fill: $text-blue;
    }
  }

  .header__search-close {
    width: 16px;
    height: 16px;
    margin-right: 50px;
    margin-left: 102px;
    cursor: pointer;
    transition: all 0.3s;
    fill: #a6a6a6;

    &:hover {
      fill: $main-blue;
    }
  }

  .header__search-input {
    position: relative;
    width: 595px;
    margin-top: -5px;

    .header__search-close {
      width: 10px;
      height: 10px;
      margin-right: 0;
      margin-left: 0;
    }

    .header__search-circle {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: #f0f0f0;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: transparent;
      }
    }
  }

  .header__search-inner {
    display: flex;
  }
}
