.projects {
  padding: 50px 15px;
  background-color: #1d283e;
}

.projects__img {
  display: block;
  width: 100%;
}

.projects__item {
  width: calc(50% - 20px);
  margin: 10px;
}

.projects__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 560px;
  margin-top: -10px;
  margin-right: -10px;
  margin-left: -10px;
}

.projects__title {
  margin-bottom: 40px;
}

.projects__name {
  display: none;
}

.projects__desc {
  display: none;
}

@media (min-width: 414px) {
  .projects__list {
    flex-direction: row;
    max-height: unset;
  }
}

@media (min-width: 1200px) {
  .projects {
    @include page-wrapper;
    padding-top: 90px;
    padding-bottom: 80px;
  }

  .projects__list {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-height: unset;
  }

  .projects__item {
    width: 306px;

    &:nth-child(2) {
      margin-bottom: 120px;
    }

    &:nth-child(4) {
      margin-top: -120px;
    }

    &:nth-child(6) {
      margin-top: -120px;
    }
  }

  .projects__title {
    margin-bottom: 80px;
  }

  .projects__name {
    display: inline-block;
    margin-top: 27px;
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    font-family: $second-font;
    color: #ffffff;
  }

  .projects__desc {
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
    font-family: $second-font;
    color: #ffffff;
  }
}
