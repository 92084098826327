.title {
  margin: 0;
  font-family: $main-font;

  &--white {
    color: #ffffff;
  }

  &--section {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.05em;
  }

  &--block {
    font-size: 25px;
    line-height: 35px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
}

@media (min-width: 768px) {
  .title {
    &--section {
      font-size: 30px;
      line-height: 63px;
    }

    &--block {
      font-size: 32px;
      line-height: 39px;
    }
  }
}
