.search__title {
  margin: 0;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.search__input {
  display: block;
  width: 100%;
  height: 50px;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid $main-blue;

  &::placeholder {
    color: #333333;
  }
}

.search__label {
  position: relative;
  display: block;
}

.search__button {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: $main-blue;
  background-image: url("../../img/loupe.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  border: none;
  transition: all 0.3s;

  &:hover,
  &:focus {
    background-color: $text-blue;
  }
}


@media (min-width: 768px) {
  .search {
    max-width: 385px;
  }
}
