.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 350;
  width: calc(100% - 20px);
  padding: 15px;
  padding-bottom: 45px;
  font-family: $second-font;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  display: none;
}


.modal__close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-left: auto;
  padding: 0;
  background-color: transparent;
  border: 1px solid #dddddd;
  border-radius: 50%;
  transition: all 0.3s;
  appearance: none;
  cursor: pointer;

  &:hover {
    background-color: $main-blue;
    svg {
      fill: #ffffff;
    }
  }

  svg {
    width: 10px;
    height: 10px;
    transition: all 0.3s;
    fill: #f56669;
  }
}

.modal__title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 22px;
}

.modal__desc {
  margin-bottom: 35px;
  font-size: 14px;
  line-height: 23px;
  color: #474747;
}

.modal__button {
  margin-bottom: 30px;
  border-radius: 2px;

  span {
    font-weight: 600;
    font-size: 14px;
    font-family: $second-font;
    letter-spacing: 0.03em;
  }
}

.modal__politic {
  max-width: 315px;
  font-size: 13px;
  line-height: 20px;
  color: #000000;

  a {
    color: $text-blue;
  }
}

.modal__item {
  position: relative;
  margin-bottom: 20px;

  &--textarea {
    .modal__label {
      top: 20px;
    }
  }
}

.modal__input {
  appearance: none;
  background: #F5F6F8;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 3px;
  height: 60px;
  width: 100%;
  padding: 0 20px;
  transition: all 0.3s;

  &:focus {
    background: #ffffff;

    & + .modal__label {
      top: 0;
      padding: 0 10px;
      background: #ffffff;
      left: 10px;
    }
  }

  &:valid {
    background: #ffffff;

    & + .modal__label {
      top: 0;
      padding: 0 10px;
      background: #ffffff;
      left: 10px;
    }
  }
}


.modal__textarea {
  appearance: none;
  background: #F5F6F8;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 3px;
  height: 140px;
  width: 100%;
  padding: 0 20px;
  transition: all 0.3s;

  &:focus {
    background: #ffffff;

    & + .modal__label {
      top: 0;
      padding: 0 10px;
      background: #ffffff;
      left: 10px;
    }
  }

  &:valid {
    background: #ffffff;

    & + .modal__label {
      top: 0;
      padding: 0 10px;
      background: #ffffff;
      left: 10px;
    }
  }
}



.modal__error {
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  color: #F56669;
  position: absolute;
  right: 8px;
  top: 50%;
  opacity: 0;
  z-index: -1;

  &--show {
    opacity: 1;
    z-index: unset;
  }
}


.modal__label {
  font-weight: 600;
  font-size: 13px;
  line-height: 23px;
  color: #807E7E;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}

@media (min-width: 768px) {
  .modal {
    width: 700px;
    padding: 50px;
    padding-bottom: 50px;
  }

  .modal__close {
    position: absolute;
    right: 26px;
    top: 26px;
    width: 54px;
    height: 54px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .modal__title {
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 11px;
  }

  .modal__desc {
    max-width: 520px;
  }

  .modal__button {
    max-width: 284px;
  }

  .modal__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .modal__item {
      max-width: 284px;
      width: 284px;
      margin-bottom: 24px;
    }
  }
}
