body {
  font-weight: 400;
  font-size: 14px;
  font-family: $main-font;
  line-height: 1.7em;
  color: $black;
}

* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: unset;
}

a,
button,
label,
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
