.contacts {
  font-family: $second-font;
}

.contacts__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  min-width: 46px;
  height: 46px;
  margin-right: 16px;
  background-color: rgba(181, 181, 181, 0.15);
  border-radius: 50%;

  svg {
    width: 20px;
    height: 20px;
    fill: $main-blue;
  }
}

.contacts__list {
  @include list-reset;
  margin: 0;
  margin-top: 35px;
  margin-bottom: 53px;
}

.contacts__item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.contacts__body {
  display: flex;
  flex-direction: column;
}

.contacts__header {
  margin-bottom: 3px;
  font-size: 12px;
  line-height: 20px;
  color: #a0a0a0;
}

.contacts__map {
  margin-right: -15px;
  margin-left: -15px;
}

.contacts__text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $text-blue;
}

.about__content {
  .contacts__text {
    margin-bottom: 0;
  }
}

.contacts__input {
  width: 100%;
  height: 70px;
  margin-bottom: 15px;
  padding: 0;
  padding: 18px 32px;
  font-size: 16px;
  line-height: 34px;
  color: #939393;
  background-color: rgba(181, 181, 181, 0.15);
  border: 1px solid rgba(181, 181, 181, 0.15);
  transition: all 0.3s;
  appearance: none;

  &:focus {
    border-color: $main-blue;
  }
}

.contacts__textarea {
  width: 100%;
  height: 185px;
  margin-bottom: 25px;
  padding: 18px 32px;
  font-size: 16px;
  line-height: 34px;
  color: #939393;
  background-color: rgba(181, 181, 181, 0.15);
  border: 1px solid rgba(181, 181, 181, 0.15);

  transition: all 0.3s;
  appearance: none;

  &:focus {
    border-color: $main-blue;
  }
}

.contacts__submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
  padding-right: 32px;
  padding-left: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
}

.contacts__politic {
  font-size: 12px;
  line-height: 22px;
  color: #000000;

  a {
    color: $text-blue;
  }
}

.contacts__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  background-color: #ffffff;
  border-radius: 50%;

  svg {
    width: 7px;
    height: 11px;
    fill: $main-blue;
  }
}

@media (min-width: 1200px) {
  .contacts__inner {
    display: flex;
    flex-direction: column-reverse;
  }

  .contacts__row {
    display: flex;

    .contacts__input {
      &:first-child {
        margin-right: 15px;
      }
    }
  }

  .contacts__bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .contacts__politic {
    max-width: 350px;
  }

  .contacts__submit {
    max-width: 385px;
  }

  .contacts__list {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 45px;
  }

  .contacts__item {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .contacts__map {
    width: 865px;
    margin: 0;
    margin-bottom: 60px;
    margin-left: -55px;
  }
}
