.test {
  @include vh-check();
  content: "";
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: antiquewhite;

  p {
    @include fluidFontSize(16px, 24px, 480px, 1280px, 18px);
    margin: 0;
    line-height: 1.3;
  }

  h2 {
    margin: 0;
    font-size: 36px;
    line-height: 1.3;
  }

  h3 {
    line-height: 3;
  }

  h4 {
    font-size: 12px;
  }
}
