.why {
  padding: 30px 15px 60px 15px;
  font-family: $second-font;
}

.why__list {
  @include list-reset;
  margin-top: 30px;
}

.why__icon {
  width: 35px;
  height: 35px;
  margin-right: 17px;
  transition: all 0.3s;
  fill: $main-blue;
}

.why__top {
  display: flex;
  align-items: center;
}

.why__desc {
  margin-top: 15px;
  font-size: 14px;
  line-height: 20px;
  color: #8d8e8f;
}

.why__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
}

.why__item {
  padding: 20px 15px;
  border: 1px dashed #8e8e8e;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .why {
    @include page-wrapper;
    padding-top: 60px;
    padding-bottom: 120px;
  }

  .why__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 55px;
  }

  .why__item {
    flex-grow: 1;
    width: 33%;
    padding: 50px 30px 70px 30px;
    background: transparent;
    border: unset;

    transition: all 0.3s;

    &:hover {
      background: linear-gradient(90deg, #1b5ec5 0%, #408cf7 100%);

      .why__icon {
        fill: #ffffff;
      }

      .why__name {
        color: #ffffff;
      }

      .why__desc {
        color: #ffffff;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &:first-child {
      padding-top: 70px;
      border-bottom: 1px solid #d2d2d2;
      border-radius: 30px 0 0 0;
    }

    &:nth-child(2) {
      padding-top: 70px;
      border-right: 1px solid #d2d2d2;
      border-bottom: 1px solid #d2d2d2;
      border-left: 1px solid #d2d2d2;
    }

    &:nth-child(3) {
      padding-top: 70px;
      border-bottom: 1px solid #d2d2d2;
      border-radius: 0 30px 0 0;
    }

    &:nth-child(4) {
      border-radius: 0 0 0 30px;
    }

    &:nth-child(5) {
      border-right: 1px solid #d2d2d2;
      border-left: 1px solid #d2d2d2;
    }

    &:nth-child(6) {
      border-radius: 0 0 30px 0;
    }
  }
}


@media (min-width: 1540px) {
  .why__list {
    margin-right: -115px;
    margin-left: -115px;
  }

  .why__item {
    &:first-child {
      padding-left: 115px;
    }

    &:nth-child(3) {
      padding-right: 115px;
    }

    &:nth-child(4) {
      padding-left: 115px;
    }

    &:nth-child(6) {
      padding-right: 115px;
    }
  }
}
